<template>
    <div class="login row d-flex justify-content-center align-items-center">
        <div class="col-md-6 col-sm-12">
            <img :src="img.login" class="img-login" />
        </div>
        <div class="col-md-6 col-sm-12">
            <div class="featured-login">
                <div class="header-login">
                    <img :src="img.logo_login" />
                    <div class="text">
                        <span class="color-1">Hệ thống cơ sở</span>
                        <br />
                        <span class="color-2">dữ liệu ngành</span>
                    </div>
                </div>
                <div class="login-form">
                    <div class="form-user">
                        <label class="label">Tài khoản</label>
                        <CustomInput
                            v-model="login.input_username"
                            @input="onChangeHandler('username', $event)"
                            :textError="err_username"
                            @keyup.enter.native="handleLogin"
                        >
                        </CustomInput>
                    </div>
                    <div class="form-pw">
                        <label class="label">Mật khẩu</label>
                        <CustomInput
                            v-model="login.input_password"
                            :showPassword="true"
                            @input="onChangeHandler('password', $event)"
                            :textError="err_password"
                            @keyup.enter.native="handleLogin"
                        >
                        </CustomInput>

                        <span v-if="errorMessage" class="error-message">
                            {{ errorMessage }}
                        </span>
                        <div class="captcha" v-if="failCount >= 5">
                            <div>
                                <label>Nhập mã Captcha</label>

                                <CustomInput
                                    v-model="login.input_captcha"
                                    placeholder=""
                                >
                                </CustomInput>
                            </div>
                            <div>
                                <img :src="base64Image" alt="" />
                            </div>
                        </div>
                    </div>

                    <CustomButton
                        label="Đăng nhập"
                        size="medium"
                        class="btn-login"
                        @click="handleLogin"
                    />
                    <!-- <CustomButton
                        label="Đăng nhập SSO UBND Tỉnh"
                        size="medium"
                        class="btn-login-sso"
                    /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import login from '@/assets/img/login/login.svg'
import logo_login from '@/assets/img/login/logo_login.svg'
import CustomButton from '@/components/CustomButton.vue'
import CustomInput from '@/components/CustomInput.vue'
import Api from '@/constants/Api'
import sendRequest from '@/services'
import { hideLoading, showLoading } from '@/utils/loading'
import { saveToSessionStorage } from '@/utils/session'
import { validateFields, handlerError } from '@/utils/validator'
export default {
    name: 'LoginComponents',
    components: { CustomInput, CustomButton },
    data() {
        return {
            img: {
                login,
                logo_login
            },
            isLoginDisabled: true,
            login: {
                input_username: '',
                input_password: '',
                input_remember: '',
                input_captcha: '',
                captchaToken: ''
            },
            token: '',
            base64Image: '',
            failCount: 0,
            errorMessage: '',

            currentDomain: null,

            err_username: '',
            err_password: ''
        }
    },
    mounted() {
        let currentDomain = window.location.hostname
        this.currentDomain = currentDomain
    },
    methods: {
        updateLoginButtonState() {
            this.isLoginDisabled =
                this.login.input_username.trim() === '' ||
                this.login.input_password.length < 3
        },
        async getMaCaptCha() {
            const token = {
                token: this.token
            }
            const response = await sendRequest(Api.auth.getMaCaptCha, null, token)
            this.base64Image = 'data:image/png;base64,' + response.dataImg
            this.captchaToken = response.tokenCap
        },
        onChangeHandler(source, newValue) {
            const errorFields = {
                username: 'err_username',
                password: 'err_password'
            }

            handlerError(this, source, newValue, errorFields)
        },
        async handleLogin() {
            try {
                const fieldsToValidate = [
                    {
                        value: this.login.input_username,
                        errorField: 'err_username'
                    },

                    {
                        value: this.login.input_password,
                        errorField: 'err_password'
                    }
                ]

                if (validateFields(this, fieldsToValidate)) {
                    showLoading()
                    const requestData = {
                        username: this.login.input_username,
                        password: this.login.input_password,

                        captcha_token: this.captchaToken
                    }
                    if (this.login.input_captcha) {
                        requestData.captcha = this.login.input_captcha
                    }

                    const response = await sendRequest(
                        Api.auth.login,
                        requestData
                    )

                    if (response.rc === 0) {
                        hideLoading()

                        this.token = response.token
                        saveToSessionStorage('auth_user', response)
                        saveToSessionStorage('auth_token', response.token)
                        saveToSessionStorage('selectedYear', 2024) // khởi tạo giá trị chọn năm
                        saveToSessionStorage('gradeLevel', []) // khởi tạo giá trị cấp học
                        saveToSessionStorage('semester', 1) // khởi tạo giá trị cấp học
                        this.$router.push('/')
                        location.reload()
                    } else if (response.rc !== 0) {
                        this.errorMessage = response.rd
                    }

                    if (response.failCount >= 5) {
                        this.errorMessage = response.rd
                        this.getMaCaptCha()
                        this.failCount = response.failCount
                    }

                    hideLoading()
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>
.login {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}
.img-login {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    background-size: cover;
}
.featured-login {
    display: flex;

    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
}
.header-login {
    display: flex;
    align-items: center;
    justify-content: center;
}
.text {
    font-size: 28px;
    font-weight: bold;
    margin-left: 30px;
    text-transform: uppercase;
}
.color-1 {
    color: #186ab2;
}
.color-2 {
    color: #186ab2;
}
.label {
    font-weight: 600;
}
.form-user,
.form-pw {
    margin-bottom: 20px;
}
.login-form {
    background-color: #eaf6ff;
    padding: 80px;
    border-radius: 16px;
    margin-top: 15px;
}
.btn-login,
.btn-login:hover {
    background-color: #186ab2;
    color: #ffffff;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 16px;
}
.btn-login-sso {
    color: #186ab2;
    background-color: #ffffff;
    border-radius: 16px;
    width: 100%;
}
.error-message {
    font-style: italic;
    color: red;
    font-weight: 500;
    font-size: 13px;
}
.captcha {
    display: flex;
    align-items: center;
}
.captcha img {
    border-radius: 16px;
    height: 36px;

    width: 100%;
    margin-top: 30px;
}
::v-deep .el-button + .el-button {
    margin: 0;
}
::v-deep .el-input__inner {
    border-radius: 16px;
}
::v-deep .el-input .el-input__clear {
    color: #333;
}
::v-deep .el-input__inner {
    border: 1px solid #787676;
}
@media (max-width: 767px) {
    .text {
        font-size: 20px;
    }
    .img-login {
        display: none;
    }
    .featured-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .login-form {
        width: 100%;
        padding: 10px;
    }
    .form-user,
    .form-pw {
        text-align: left;
        margin-bottom: 10px;
    }
    .btn-login {
        margin-top: 10px;
    }
}
</style>
